import React, { useContext, useEffect } from "react";
import AdComponent from "../../Components/AdComponent/AdComponent";
import Layout from "../../Components/Layout/Layout";
import classes from "./Home.module.css";
import HomeDateSection from "./HomeDateSection";
import HomeFeaturedStoriesAndLiveScores from "./HomeFeaturedStoriesAndLiveScores";
import HomeTopStoriesAndBreakingNews from "./HomeTopStoriesAndBreakingNews";
import HomeTransferNews from "./HomeTransferNews";
// import HomeVideos from "./HomeVideos";
import { AppContext } from "../../Context/AppContext";
import { MatchesContext } from "../../Context/MatchesContext";
import { CircularProgress } from "@mui/material";
import HomeTodayLastYear from "./HomeTodayLastYear";
import HomeBasketBallNews from "./HomeBasketBallNews";
import HomeGolfAndBaseBall from "./HomeGolfAndBaseBall";
import HomeChampionsLeague from "./HomeChampionsLeagueNews";
// import HomeWomenSportNews from "./HomeWomenSportNews";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import HomeLightBoxContainer from "./HomeLightBoxContainer";
import { useState } from "react";
import { AdContext } from "../../Context/AdContext";
import runOneSignal from "../../OneSignalConfig";
import HomeEmailSIgnupContainer from "./HomeEmailSIgnupContainer";
import { PostMetadata } from "../ArticleContainer/ArticleContainer";

const Home = () => {
  // context
  const {
    isSendingRequest,
    fetchAllHeadlines,
    fetchTopStories,
    fetchBacketBallNews,
    fetchChampionsLeagueNewsHandler,
    fetchWomenSportNews,
    getCreatedNews,
    createdNews,
    fetchAfconNews,
  } = useContext(AppContext);
  const { getAllLeaguesANdEventsAlt } = useContext(MatchesContext);

  // States
  const [displayLightBox, setDisplayLightBox] = useState(false);
  const [displayModalForSignup, setDisplayModalForSignup] = useState(false);
  const { homePageAds } = useContext(AdContext);

  // effects
  useEffect(() => {
    fetchAllHeadlines();
    getAllLeaguesANdEventsAlt();
    fetchTopStories();
    fetchBacketBallNews();
    fetchChampionsLeagueNewsHandler();
    fetchWomenSportNews();
    getCreatedNews();
    fetchAfconNews();
    setDisplayLightBox(true);
    setDisplayModalForSignup(false);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    runOneSignal();
  });

  const adsAdrayCopyForLightbox = homePageAds?.filter((data) => {
    return data.section === "540px-by-540px-lightbox-popup";
  });

  return (
    <Layout>
      <PostMetadata metadata={{ title: "Home" }} />
      {isSendingRequest ? (
        <div className={classes.loading}>
          <CircularProgress
            size="1rem"
            color="inherit"
            style={{ color: "#ffd91b" }}
          />
        </div>
      ) : (
        <div className={classes.container}>
          {displayLightBox && adsAdrayCopyForLightbox.length > 0 && (
            <AcceptedModal
              body={
                <HomeLightBoxContainer
                  onClick={() => {
                    setDisplayLightBox(false);
                  }}
                />
              }
              onClick={() => {
                setDisplayLightBox(false);
              }}
            />
          )}

          {displayModalForSignup && (
            <AcceptedModal
              body={
                <HomeEmailSIgnupContainer
                  onClick={() => {
                    setDisplayModalForSignup(false);
                  }}
                />
              }
              style={{ maxHeight: "400px" }}
              onClick={() => {
                setDisplayModalForSignup(false);
              }}
            />
          )}
          <div className={classes.bodyMain}>
            <div className={classes.dateSection}>
              <HomeDateSection showDate={true} />
            </div>
            <div className={classes.adContainer}>
              <AdComponent
                adArray={homePageAds}
                section="970px-by-250px-under-the-date-display-on-the-home"
              />
            </div>
            <div>
              <HomeTopStoriesAndBreakingNews />
            </div>
            <div className={classes.adContainer}>
              <AdComponent
                adArray={homePageAds}
                section="728px-by-90px-between-the-first-home-section-and-the-second-home-section"
              />
            </div>
            <div className={classes.homeFeaturedstoriesAndLiveScores}>
              <HomeFeaturedStoriesAndLiveScores />
            </div>

            <div>{createdNews.length > 0 && <HomeBasketBallNews />}</div>

            {/* <div>
              <HomeVideos />
            </div> */}

            <div>
              <HomeTransferNews />
            </div>

            <div>
              <HomeGolfAndBaseBall />
            </div>

            <div>
              <HomeTodayLastYear />
            </div>

            <div>
              <HomeChampionsLeague />
            </div>

            <div>{/* <HomeWomenSportNews /> */}</div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Home;
