import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  // State
  const [headlines, setHeadlines] = useState([]);
  const [offsetValue, setOffsetValue] = useState(0);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [error, setError] = useState("");
  const [selectedArticle, setSelectedArticle] = useState();
  const [articles, setArticles] = useState([]);
  const [transferNews, setTransferNews] = useState([]);
  const [transferNewsOffsetValue, setTransferNewsOffsetValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedNews, setSearchNews] = useState([]);
  const [searchOffsetValue, setSearchOffsetValue] = useState(0);
  const [thisDayLastYear, setThisDayLastYear] = useState([]);
  const [topStories, setTopStories] = useState([]);
  const [displaySearchContainer, setDisplaySearchContainer] = useState(false);
  const [basketBallNews, setBasketBallNews] = useState([]);
  const [championsLeagueNews, setChampionsLeagueNews] = useState([]);
  const [championsLeagueOffset, setChampionsLeagueOffset] = useState(0);
  const [womenSportNews, setWomenSportNews] = useState([]);
  const [womenSportNewsOffset, setWomenSportNewsOffset] = useState(0);

  if (offsetValue > 100) {
    setOffsetValue(0);
  }

  if (transferNewsOffsetValue > 100) {
    setTransferNewsOffsetValue(0);
  }

  if (searchOffsetValue > 100) {
    setSearchOffsetValue(0);
  }

  if (championsLeagueOffset > 100) {
    setSearchOffsetValue(0);
  }

  if (womenSportNewsOffset > 100) {
    setSearchOffsetValue(0);
  }

  const fetchAllHeadlines = () => {
    setIsSendingRequest(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNews?type=all-headlines`
      )
      .then((res) => {
        setHeadlines(res.data.data);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
        setError(err.message);
      });
  };

  useEffect(() => {
    fetchAllHeadlines();

    // eslint-disable-next-line
  }, [offsetValue]);

  // fetchTopStories in last 36h
  const fetchTopStories = () => {
    setIsSendingRequest(true);

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNews?type=top-stories`
      )
      .then((res) => {
        setTopStories(res.data.data);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  const fetchSelectedArticle = (id) => {
    setIsSendingRequest(true);
    setSelectedArticle();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNewsByID?uri=${id}`
      )
      .then((res) => {
        setSelectedArticle(res.data.data);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setSelectedArticle();
        setIsSendingRequest(false);
      });
  };

  const fetchSelectedArticle2 = (id) => {
    setSelectedArticle();
    axios
      .get(`${process.env.REACT_APP_PA_API_DOMAIN}/v1/item/${id}`, {
        headers: {
          apikey: process.env.REACT_APP_PA_API_KEY,
          Accept: "application/json",
        },
      })
      .then((res) => {
        setSelectedArticle(res.data);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  // Fetch articles (Football news)
  const fetchArticles = () => {
    setArticles([]);
    setIsSendingRequest(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNews?type=football-news`
      )
      .then((res) => {
        setArticles(res.data.data);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  // fetch transfer news
  const fetchTransferNews = () => {
    setIsSendingRequest(true);
    setTransferNews([]);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNews?type=tranfer-news`
      )
      .then((res) => {
        setTransferNews(res.data.data);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  useEffect(() => {
    fetchTransferNews();

    // eslint-disable-next-line
  }, [transferNewsOffsetValue]);

  const searchHandler = () => {
    setIsSendingRequest(true);
    setSearchNews([]);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNewsByTag?tag=${searchQuery}`
      )
      .then((res) => {
        setSearchNews(res.data.data);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  useEffect(() => {
    searchHandler();

    // eslint-disable-next-line
  }, [searchOffsetValue]);

  // This day last year
  const fetchThisDayLastYear = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/thisDayLastYear`)
      .then((res) => {
        setThisDayLastYear(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchThisDayLastYear();

    // eslint-disable-next-line
  }, [searchOffsetValue]);

  // fetch basket ball news, same format, same endpoint just change the football filter to basketball
  const fetchBacketBallNews = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNews?type=football-news`
      )
      .then((res) => {
        setBasketBallNews(res.data.data);
      })
      .catch((err) => {});
  };

  // Champions League News
  const fetchChampionsLeagueNewsHandler = () => {
    setIsSendingRequest(true);
    setChampionsLeagueNews([]);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNews?type=champions-leagues`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setChampionsLeagueNews(res.data.data);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  const [competitionNews, setCompetitionNews] = useState([]);

  const fetchAfconNews = () => {
    setTopStories([]);
    setIsSendingRequest(true);
    axios
      .get(
        `${process.env.REACT_APP_PA_API_DOMAIN}/v1/item?subject=tag:africa-cup-of-nations`,

        {
          headers: {
            apikey: process.env.REACT_APP_PA_API_KEY,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setCompetitionNews(res.data.item);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  // Champions League News
  const fetchWomenSportNews = () => {
    setIsSendingRequest(true);
    setWomenSportNews([]);
    axios
      .get(
        `${process.env.REACT_APP_PA_API_DOMAIN}/v1/item?subject=tag:women&offset=${womenSportNewsOffset}`,
        {
          headers: {
            apikey: process.env.REACT_APP_PA_API_KEY,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setWomenSportNews(res.data.item);
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
      });
  };

  const [createdNews, setCreatedNews] = useState([]);

  const getCreatedNews = (id) => {
    setIsSendingRequest(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/editor/newsList`)
      .then((res) => {
        setCreatedNews(
          res.data.posts.reverse().map((data) => {
            return { ...data, isActive: false };
          })
        );
        setIsSendingRequest(false);
      })
      .catch((err) => {
        setIsSendingRequest(false);
        setError(err.response ? err?.response?.data?.message : err.message);
      });
  };

  const getCreatedNewsById = (id) => {
    if (id) {
      setIsSendingRequest(true);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/editor/editNews/${id}`
        )
        .then((res) => {
          // setParticularCreatedNews(res.data.data);
          setSelectedArticle(res.data.data);

          setIsSendingRequest(false);
        })
        .catch((err) => {
          setIsSendingRequest(false);
          setError(err.response ? err?.response?.data?.message : err.message);
        });
    }
  };

  useEffect(() => {
    fetchChampionsLeagueNewsHandler();
    // eslint-disable-next-line
  }, [championsLeagueOffset]);

  useEffect(() => {
    fetchWomenSportNews();
    // eslint-disable-next-line
  }, [womenSportNewsOffset]);

  return (
    <AppContext.Provider
      value={{
        headlines,
        isSendingRequest,
        error,
        fetchSelectedArticle,
        selectedArticle,
        fetchAllHeadlines,
        fetchArticles,
        articles,
        fetchTransferNews,
        transferNews,
        setTransferNewsOffsetValue,
        searchHandler,
        searchQuery,
        setSearchQuery,
        searchedNews,
        setSearchOffsetValue,
        thisDayLastYear,
        fetchTopStories,
        topStories,
        setOffsetValue,
        fetchThisDayLastYear,
        displaySearchContainer,
        setDisplaySearchContainer,
        basketBallNews,
        fetchBacketBallNews,
        fetchChampionsLeagueNewsHandler,
        championsLeagueNews,
        fetchWomenSportNews,
        womenSportNews,
        setChampionsLeagueOffset,
        setWomenSportNewsOffset,
        createdNews,
        setCreatedNews,
        getCreatedNews,
        getCreatedNewsById,
        competitionNews,
        fetchAfconNews,
        fetchSelectedArticle2,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
