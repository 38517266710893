import classes from "./HomeEmailSIgnupContainer.module.css";

const HomeEmailSIgnupContainer = (props) => {
  return (
    <section className={classes.container}>
      <h4>Sign up for our Email newsletter</h4>

      <form>
        <label htmlFor="email">Email</label>
        <input type="email" name="email" id="email" placeholder="me@xyz.com" />
        <button
          onClick={(e) => {
            e.preventDefault();
            props.onClick();
          }}
        >
          Subscribe
        </button>
      </form>
    </section>
  );
};

export default HomeEmailSIgnupContainer;
